.item {
  position: relative;

  display: block;

  width: 32%;
  height: 163px;
  margin-right: 25px;
  margin-bottom: 36px;
  padding: 22px 20px;

  border-radius: 20px;
  background: var(--palette-default);
}

.row {
  display: flex;
  justify-content: flex-start;

  width: 100%;
  margin-bottom: 15px;
}

.row:last-child {
  margin-bottom: 0;
}

.titleRight {
  display: flex;
  align-items: center;

  width: 45%;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.rightLabel {
  flex-grow: 1;

  width: calc(55% - 5px);
  padding-left: 5px;

  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: 400;
}

.iconsWrapper {
  position: absolute;
  top: 0;
  right: 12px;

  display: grid;

  width: auto;
  grid-template-columns: auto auto;
  gap: 6px;
}

.actionIcon {
  width: 17px;
  height: 16px;
  margin-right: 3px;
}

.iconsWrapper .actionButton {
  padding: 4px;
}

.actionIcon:last-child {
  margin-right: 0;
}

.actionIcon:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .titleRight {
    font-size: 12px;
  }

  .item {
    width: 100%;
  }

  .iconsWrapper {
    display: none;
  }

  .rightLabel {
    max-width: 50%;
  }
}

@media (min-width: 769px) and (max-width: 1054px) {
  .titleRight {
    width: 55%;

    font-size: 9px;
  }

  .rightLabel {
    max-width: 28%;
  }
}

@media (min-width: 1054px) and (max-width: 1300px) {
  .titleRight {
    width: 50%;

    font-size: 11px;
  }

  .item {
    width: 35%;
  }

  .rightLabel {
    max-width: 33%;
  }
}

@media (min-width: 1301px) {
  .rightLabel {
    max-width: 38%;
  }
}
