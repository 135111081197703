.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.searchHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.addSmallScreen {
  display: none !important;
}

.searchFormContainer {
  display: flex;
  align-items: center;
}

.searchFormContainer .chartButton {
  width: 36px;
  height: 36px;
  margin: 0 10px;
  padding: 0;

  border: 1px solid var(--palette-primary-disabled);
  background-color: var(--palette-default);
}

.deleteButton {
  cursor: pointer;

  color: var(--palette-error-hover) !important;
  border: none;
  background: none;

  font-size: 13px !important;
}

.deleteButton:hover {
  background: none !important;
}

.deleteButtonSmallScreen {
  display: none !important;

  cursor: pointer;

  color: var(--palette-error-hover) !important;
  border: none;
  background: none;

  font-size: 13px !important;
}

.tabsWrapper {
  position: relative;
}

.modal {
  width: 496px !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .addBtn,
  .addSmallScreen {
    display: none !important;
  }

  .search {
    width: 220px !important;
  }

  .table > div:first-child > div:first-child > div:last-of-type {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 905px) {
  .deleteButtonSmallScreen,
  .addSmallScreen {
    display: block !important;
  }

  .deleteButton,
  .addBtn {
    display: none !important;
  }
}

@media (min-width: 993px) and (max-width: 1110px) {
  .addSmallScreen,
  .deleteButtonSmallScreen {
    display: block !important;
  }

  .deleteButton,
  .addBtn {
    display: none !important;
  }
}
