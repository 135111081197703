body {
  overflow-x: hidden;
  overflow-y: auto;

  width: 100%;
  max-width: 100%;
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;

  color: var(--palette-backdrop-color);
  background: var(--palette-body);

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;

  outline: none;

  font-family: "Poppins", sans-serif;
  -webkit-user-drag: none;
}

img,
img *,
svg,
svg * {
  user-select: none;
}
