.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.tabsWrapper {
  position: relative;
}
