.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
}

.searchHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.searchFormContainer {
  display: flex;
  align-items: center;
}

.searchField {
  margin-right: 24px;
}

.search {
  margin-right: 10px;
}

.deleteButton {
  cursor: pointer;

  color: var(--palette-error-hover) !important;
  border: none;
  background: none;

  font-size: 13px !important;
}

.deleteButton:hover {
  background: none !important;
}

.deleteButtonSmallScreen {
  display: none !important;

  cursor: pointer;

  color: var(--palette-error-hover) !important;
  border: none;
  background: none;

  font-size: 13px !important;
}

.addSmallScreen {
  display: none !important;
}

.sectionName {
  margin-bottom: 24px;

  font-size: 16px;
  font-weight: 500;
}

.modal {
  overflow: visible !important;

  width: 496px !important;
  padding: 0 !important;
}

.editModal {
  width: 931px !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .addFullScreen,
  .addSmallScreen {
    display: none !important;
  }

  .search {
    width: 220px !important;
  }

  .table > div:first-child > div:first-child > div:last-of-type {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 905px) {
  .deleteButtonSmallScreen,
  .addSmallScreen {
    display: block !important;
  }

  .deleteButton,
  .addFullScreen {
    display: none !important;
  }
}

@media (min-width: 993px) and (max-width: 1110px) {
  .deleteButtonSmallScreen,
  .addSmallScreen {
    display: block !important;
  }

  .deleteButton,
  .addFullScreen {
    display: none !important;
  }
}
