.collapseWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 6px;
}

.leftPart {
  display: flex;
}

.revertArrow {
  transform: rotate(-180deg);
}

.label {
  margin-left: 6px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
}

.inputDate > div {
  flex-wrap: nowrap;
}
