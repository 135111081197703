.wrapper {
  position: relative;
}

.tableHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 20px;
}

.searchWrapper {
  display: flex;
  align-items: center;
}

.search {
  margin-right: 10px;
}

.viewModal {
  width: 437px !important;
  padding: 48px !important;
}

.mainModal {
  width: 496px !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .addSmallScreen,
  .addBtn {
    display: none !important;
  }

  .search {
    width: 220px !important;
  }

  .table > div:first-child > div:first-child > div:last-of-type {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1054px) {
  .addSmallScreen {
    display: block;
  }

  .addBtn {
    display: none !important;
  }
}

@media (min-width: 1055px) {
  .addBtn {
    display: block;
  }

  .addSmallScreen {
    display: none !important;
  }
}
