.container {
  padding: 24px 10px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.imageWrapper {
  flex-grow: 0;

  width: 35%;
  height: 354px;
  margin-right: 36px;
  padding: 32px;

  background-color: var(--palette-tertiary-disabled);
}

.imagePreviewContainer {
  position: relative;
}

.imgPreview {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 122px;
  height: 122px;

  border-radius: 8px;
  background: var(--palette-primary-disabled);
}

.imgPreviewCircle {
  width: 122px;
  height: 122px;
  margin: 0 auto;

  border-radius: 50%;
}

.fieldsWrapper {
  flex-grow: 1;

  padding: 10px 0;
}

.label {
  margin-bottom: 12px;

  text-transform: uppercase;

  color: var(--input-placeholder-color);
  border-bottom: 1px solid var(--input-placeholder-color);

  font-size: 10px;
}

.placeWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 36px;
}

.placeWrapper > div:first-of-type {
  margin-right: 20px;
}

.field {
  width: 100%;
}

.buttonWrapper {
  justify-content: right !important;
}

/*upload style*/

.uploadInfo {
  --button-text-color: var(--button-primary-background);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.uploadText,
.acceptedText {
  margin-top: 40px;

  text-align: center;

  color: var(--palette-text-primary);

  font-size: 13px;
}

.uploadText {
  max-width: 190px;

  line-height: 10px;
}

.acceptedFormats {
  text-transform: uppercase;
}

.acceptedText {
  display: flex;
  flex-direction: column;

  margin-top: 16px;
}

.uploadInfo .uploadBtn {
  display: inline-block;

  padding: 0;

  font-size: 13px;
}
