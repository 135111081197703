.searchHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-bottom: 24px;
}

.searchField {
  margin-right: 10px;
}

.modal {
  width: 496px !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .searchField {
    width: 220px !important;
  }
}
