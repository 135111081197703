.container {
  position: relative;
}

.header {
  position: absolute;
  top: 26px;
  right: 26px;

  cursor: pointer;
}

.dateInput > div {
  flex-wrap: nowrap;
}

.dateInput > div > div:first-child {
  margin-right: 5px;
}

.errorMsg {
  position: absolute;
  top: 20px;

  width: 80% !important;
  max-width: 80% !important;
}

.body {
  padding: 48px;
}

.body :global(.message-error),
.body :global(.message-default) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.name {
  margin-bottom: 35px;

  font-size: 22px;
  font-weight: 500;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 24px;
}

.infoWrapper {
  margin-bottom: 24px;
}

.infoWrapper > div:not(:last-child) {
  margin-bottom: 16px;
}
