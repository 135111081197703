.theme {
  /* Layout ↓ */
  --sidebar-width: 220px;
  --cpc-palette: #be529e;
  --cpc-palette-background: #f8eef5;
  --palette-backdrop-color: #23282d;
  /* Layout ↑ */

  /* Gantt Timeline ↓ */
  --gantt-timeline-background: var(--palette-default);
  --gantt-timeline-period-decoration-color: var(--palette-text-secondary);
  --gantt-timeline-weekend-day-color: var(--palette-primary);
  --gantt-timeline-present-date-decorator-background: var(--palette-primary);
  --gantt-timeline-group-title-color: var(--palette-primary);
  --gantt-timeline-range-date-color: var(--palette-text-secondary);
  --gantt-timeline-item-title-right-border-color: var(--palette-secondary);
  --gantt-timeline-item-color: var(--palette-default);
  --gantt-timeline-current-time-line-background: var(--palette-primary);
  /* Gantt Timeline ↑ */

  /* Breadcrumbs ↓ */
  --breadcrumbs-link-color: var(--palette-text-secondary);
  /* Breadcrumbs ↑ */

  /* Link ↓ */
  --link-primary: #3858ca;
  /* Link ↑ */
}
