.sidebar {
  border-radius: 10px 5px 5px 10px;
}

/* Field */

.fieldWrapper {
  position: relative;

  width: 100%;

  border-bottom: 1px solid var(--input-border-disabled);
}

.wrapper {
  position: relative;
}

.field input {
  padding-right: 75px !important;

  border: none !important;
  border-radius: 0;
}

.field > div:last-of-type {
  display: none;
}

.nameWrapper {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.menuWrapper > div,
.menuWrapper > div > button {
  background: transparent;
}

.menuWrapper > div > button {
  height: 46px;
}

.inputWrapper {
  width: 100%;
}

.icons,
.editIcons {
  position: absolute;
  top: 0;
  right: 5px;

  display: inline-flex;

  transform: translateY(70%);
}

.editIcons {
  right: 13px;

  height: 100%;

  transform: translateY(0);
}

.editIcons > button {
  height: 100%;
}

.icons > button {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  padding: 0 !important;
}

/* Buttons */

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;

  margin: 0 0 20px 0;
}

.buttonsMargin {
  margin: 20px 0;
}

/* Button */

.roleBtn {
  display: block;
  overflow: hidden;

  width: 100%;
  padding: 14px 14px 14px 24px;

  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;

  color: var(--palette-text-secondary);
  border: 0;
  border-left: 4px solid transparent;
  background: transparent;

  font-size: 12px;
  font-weight: normal;
  font-style: normal;
}

.editInput {
  padding-left: 10px;

  pointer-events: visible;
}

input.editInput {
  padding-right: 75px;
}

.createBtn {
  background-color: transparent !important;
}

.activeBtn {
  border-top: 1px solid var(--input-border-disabled);
  border-bottom: 1px solid var(--input-border-disabled);
  background: var(--palette-default);
}

.activeBtn > .roleBtn {
  color: var(--palette-text-default);
  border-color: var(--palette-primary);
}

.activeBtn input {
  padding-right: 60px;
}

.nameWrapper:first-child > .activeBtn {
  border-top: none;
}
