.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 36px;
}

.item,
.itemChart {
  display: block;

  width: 30%;
  min-height: 163px;
  margin-right: 25px;
  padding: 18px 20px;

  border-radius: 20px;
  background: var(--palette-default);
}

.itemChart {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item:last-child {
  position: relative;

  flex-grow: 1;

  width: 0;
  margin-right: 0;
}

.row {
  display: flex;
  justify-content: flex-start;

  width: 100%;
  margin-bottom: 15px;
}

.row:last-child {
  margin-bottom: 0;
}

.statistic {
  display: flex;
  flex-direction: column;

  width: 30%;
}

.stubWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  height: 20px;
}

.cto {
  color: var(--button-primary-background);
  border: 1px solid var(--button-primary-background);
  background: var(--palette-default-hover);
}

.coreg {
  color: var(--button-primary-active-background);
  border: 1px solid var(--button-primary-active-background);
  background: var(--palette-default-hover);
}

.cpc {
  color: var(--cpc-palette);
  border: 1px solid var(--cpc-palette);
  background: var(--cpc-palette-background);
}

.cto,
.coreg,
.cpc {
  margin-right: 3px;
  padding: 0 8px;

  text-align: center;

  border-radius: 22px;

  font-size: 11px;
}

.typeStub {
  width: 20px;
  height: 5px;
  margin-right: 12px;

  border-radius: 22px;

  font-size: 11px;
}

.typeStub:last-child {
  margin-bottom: 0;
}

.typeValue {
  text-transform: uppercase;

  font-size: 13px;
  font-weight: 400;
}

.counter {
  margin-bottom: 8px;
  padding-right: 8px;

  text-align: right;

  color: var(--palette-text-secondary);

  font-size: 10px;
}

.ctoStub {
  background: var(--button-primary-background);
}

.coregStub {
  background: var(--button-primary-active-background);
}

.cpcStub {
  background: var(--cpc-palette);
}

.emptyStub {
  background: var(--palette-primary-disabled);
}

.emptyChart {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.icon {
  width: 14px;
  height: 13px;
  margin-right: 6px;
}

.iconsWrapper {
  position: absolute;
  top: 8px;
  right: 12px;

  display: grid;

  width: auto;
  grid-template-columns: auto auto;
  gap: 6px;
}

.iconsWrapper .actionButton {
  padding: 4px;
}

.statusIcon {
  margin-right: -3px;
}

button > .status {
  color: var(--link-primary);
}

.actionIcon {
  width: 17px;
  height: 16px;
  margin-right: 3px;
}

.actionIcon:last-child {
  margin-right: 0;
}

.actionIcon:hover {
  cursor: pointer;
}

.chart {
  position: relative;

  width: 100%;
  height: 100%;
}

.totalSumWrapper {
  position: absolute;
  top: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.totalCount {
  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: 500;
}

.totalClick {
  color: var(--palette-text-secondary);

  font-size: 10px;
  font-weight: 400;
}

.emptyTypes {
  padding: 0 12px;
}

.labelWrap {
  width: 75%;
}

.label {
  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: 400;
}

.rightLabel {
  display: flex;
  align-items: center;
  flex-grow: 1;

  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: 400;
}

.title,
.titleRight {
  display: flex;
  align-items: center;

  width: 35%;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.typeContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 75%;
}

.titleRight {
  width: 30%;
}

.rightLabel {
  text-transform: capitalize;
}

@media (max-width: 689px) {
  .container {
    display: block;
  }

  .item:first-child {
    width: 100%;
  }

  .item,
  .itemChart {
    width: 100%;
    margin-bottom: 10px;
  }

  .item:last-child {
    width: 100%;
    margin-bottom: 0;
  }

  .counter {
    padding-right: 0;

    text-align: left;
  }

  .iconsWrapper {
    display: none;
  }
}

@media (min-width: 690px) and (max-width: 1150px) {
  .label,
  .rightLabel,
  .title,
  .titleRight {
    font-size: 11px;
  }

  .row {
    margin-bottom: 12px;
  }

  .counter {
    padding-right: 0;
  }

  .coreg,
  .cpc,
  .cto {
    padding: 0 4px;

    font-size: 10px;
  }

  .emptyStub,
  .ctoStub,
  .cpcStub,
  .coregStub {
    display: none;
  }

  .stubWrapper {
    justify-content: flex-end;
  }

  .item,
  .itemChart {
    width: 32%;
    margin-right: 15px;
  }
}

@media (max-width: 1280px) {
  .typeValue {
    font-size: 12px;
  }
}
