.container {
  background-color: var(--tab-selected-background);
}

.wrapper {
  padding: 48px;
}

.overview {
  margin-bottom: 24px;
  padding: 48px 24px 24px 48px;
}

.deleteWrapper,
.overview {
  border-radius: 10px;
  background-color: var(--palette-tertiary-disabled);
}

.deleteWrapper {
  padding: 22px;
}

.title {
  margin-bottom: 24px;

  font-size: 16px;
  font-weight: 500;
}

.detailsWrapper {
  display: flex;
  justify-content: flex-start;
}

.fields {
  display: grid;
  align-items: center;

  margin-left: 24px;
  padding: 5px 0;
}

.name {
  margin-bottom: 10px;

  font-size: 22px;
}

.owner {
  color: var(--palette-primary) !important;
}

.row {
  display: flex;
  justify-content: flex-start;

  width: 100%;
  margin-bottom: 5px;
}

.row:last-of-type {
  margin-bottom: 0;
}

.subtitle {
  display: flex;
  align-items: center;

  width: 45%;

  font-size: 12px;
}

.label {
  display: flex;
  align-items: center;
  flex-grow: 1;

  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: 400;
}

.btnWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 30px;
}

.removeBtn {
  color: var(--palette-error) !important;
}

.modal {
  width: 900px !important;
}

@media (max-width: 768px) {
  .modal {
    width: 100% !important;
  }
}

@media (min-width: 769px) and (max-width: 905px) {
  .modal {
    width: 100% !important;
  }
}

@media (min-width: 993px) and (max-width: 1110px) {
  .modal {
    width: 100% !important;
  }
}
