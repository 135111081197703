.row {
  display: flex;
  align-items: center;

  margin-bottom: 15px;
}

.checkbox {
  margin-right: 6px;
}

.ellipsis {
  max-width: 90%;
}
