.wrapper {
  display: flex;
  align-items: center;

  padding: 0 48px;
}

.typography {
  list-style-type: none;

  text-transform: capitalize;

  color: var(--breadcrumbs-link-color);

  font-size: 12px;
  font-weight: 500;
}

.li {
  list-style-type: none;
}

.link {
  display: flex;
  align-items: center;

  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;

  color: var(--breadcrumbs-link-color);

  font-size: 12px;
  font-weight: 400;
}

.list {
  display: flex;
  align-items: center;

  margin: 0;
  padding: 0;

  list-style-type: none;
}

.separator {
  padding: 0 8px;
}

@media (max-width: 992px) {
  .wrapper {
    display: none;
  }
}
