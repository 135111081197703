.container {
  padding: 24px 10px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 30px;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
}

.contentWrapp {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentWrapp > div {
  margin-right: 30px;
}

.contentWrapp > div:last-of-type {
  margin-right: 0;
}

.label {
  text-transform: uppercase;

  font-size: 12px !important;
  font-weight: 600 !important;
}

.field {
  width: 100%;
  margin-top: 6px;
}

.generateIcon > div > button {
  top: 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions button:first-of-type {
  margin-right: 10px;
}

.buttonWrapper {
  justify-content: right !important;
}

.icon {
  width: 12px;
  height: 12px;
}

.btn,
.addGroupBtn {
  color: var(--button-text-active-color) !important;
}

.addGroupBtn {
  margin-bottom: 25px;

  font-size: 12px !important;
}

.password {
  position: relative;
}

.copyWrapper {
  position: absolute;
  top: 25px;
  right: -30px;
}

.copyImg {
  width: 15px;
  height: 15px;
}

.groupTitle {
  color: var(--label-color);

  font-size: 18px;
}

@media (max-width: 768px) {
  .row {
    grid-template-columns: 100%;
  }
}
