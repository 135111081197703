.wrapper {
  position: relative;
}

.tableHeader {
  display: flex;
  align-items: center;

  width: 100%;
  margin-bottom: 20px;
}

.spacer {
  flex: 1 1 auto;
}

.deleteModalWrapperSmall {
  width: 398px !important;
  padding: 34px 34px 48px !important;
}

.mainModal {
  width: 496px !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .addBtn {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .table > div:first-child > div:first-child > div:last-of-type {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1054px) {
  .addBtnTitleLong {
    display: none !important;
  }
}

@media (min-width: 1055px) {
  .addBtn {
    display: block;
  }
}
