.jsonEditorWrap {
  margin-top: 16px;
}

.jsonEditorWrap > div:first-child {
  margin-bottom: 6px;

  color: var(--palette-tertiary-focus);

  font-size: 12px;
  font-weight: 600;
}

.jsonEditor > div {
  max-height: 150px;

  border: 1px solid var(--palette-primary-disabled);
  border-radius: 10px;
}

:global(.CodeMirror-gutters) {
  width: 31px;

  border-right: 1px solid var(--palette-primary-disabled);
  background: var(--palette-primary-disabled);
}

:global(.CodeMirror-linenumber) {
  display: flex;
  justify-content: center;

  color: var(--palette-tertiary-focus);
}

.urlLabel,
.label {
  margin-bottom: 6px;

  color: var(--palette-tertiary-focus);

  font-size: 12px;
  font-weight: 600;
}

.urlLabel span,
.label span {
  color: var(--palette-info-active);
}

.urlParameterBody {
  display: flex;

  height: 56px;
}

.urlParameterBody :global(.message-error),
.urlParameterBody :global(.message-default) {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.urlParameterBody > div:not(:last-child) {
  margin-right: 10px;
}

.urlParameterBody > div:first-child {
  margin-right: 12px;
}

.urlParameterImgWrapper {
  display: flex;
  align-items: center;

  height: 36px;
}

.urlParameterImgWrapper button {
  padding: 0;
}

.addUrlBtn {
  margin-bottom: 16px;
}

.radioWrapper {
  display: flex;

  margin-bottom: 24px;
}

.radioWrapper > div:not(:last-child) {
  margin-right: 15px;
}
