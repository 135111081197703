/* User */
.cell {
  max-width: 240px;

  color: var(--label-color);
}

/* Date */

.date {
  width: 100%;
  max-width: 220px;

  white-space: nowrap;
}

.date img {
  margin: 0 6px 0 14px;
}

.date img:first-child {
  margin: 0 6px 0 0;
}

button.btn {
  padding: 8px 0;

  color: var(--button-text-disabled-color);
}

button.btn:hover,
button.btn:focus {
  cursor: default;

  color: var(--button-text-disabled-color);
}
