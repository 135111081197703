/* User */
.cell {
  max-width: 240px;

  color: var(--label-color);
}

.userName {
  width: 100px;
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin: 0 5px 0 0;

  border: 1px solid var(--input-border);
  border-radius: 10px;
}

/* Date */

.date {
  width: 100%;
  max-width: 220px;

  white-space: nowrap;
}

.date img {
  margin: 0 6px 0 14px;
}

.date img:first-child {
  margin: 0 6px 0 0;
}

button.btn {
  padding: 8px 0;

  color: var(--button-text-disabled-color);
}

button.btn:hover,
button.btn:focus {
  cursor: default;

  color: var(--button-text-disabled-color);
}

.hint {
  padding: 5px;
}

.userWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin: 0 14px 0 0;

  border: 1px solid var(--input-border);
  border-radius: 10px;
}
