.container {
  display: flex;
  overflow-x: scroll;
  justify-content: space-between;

  background-color: var(--tab-selected-background);
}

.sidebar {
  width: 40%;
  min-width: 180px;

  background-color: var(--palette-tertiary-disabled);
}

.content {
  flex-grow: 1;

  width: 100%;
  min-width: 800px;
  padding: 24px;
}

.tabs {
  justify-content: space-between;

  width: 100%;
  column-gap: 0 !important;
}

.tab {
  flex-shrink: 1 !important;

  width: 100%;
  max-width: 100% !important;
  padding-top: 0 !important;
  padding-bottom: 3px !important;

  border-bottom: 2px solid var(--tab-color);
  background-color: transparent !important;
}

.tab > span {
  text-transform: uppercase;
}

.selectedTab {
  border-bottom: 2px solid var(--palette-primary);
}

.selectedTab > span {
  color: var(--palette-primary) !important;
}

@media (max-width: 1110px) {
  .tab {
    padding-top: 7px !important;
    padding-bottom: 36px !important;

    border-bottom: none;
  }

  .selectedTab {
    border-bottom: 2px solid var(--palette-primary);
    border-radius: 0 !important;
  }

  .tabs {
    column-gap: 6px !important;
  }

  .tab > span {
    text-transform: capitalize;
  }
}
