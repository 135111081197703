.makeSmaller img {
  max-width: 16px;
}

.cell {
  max-width: 200px;
}

@media (max-width: 992px) {
  .hideOnMobile > div:first-child {
    display: none !important;
  }
}
