.container {
  padding: 10px 0;

  text-align: center;
}

.title {
  text-transform: uppercase;

  color: var(--palette-grey-active);
}

.btn {
  margin: 0 auto;
  margin-top: 16px;
}
