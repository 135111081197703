.deleteButton {
  color: var(--palette-error);

  font-weight: normal;
}

.editButton,
.copyButton {
  color: var(--palette-text-default);

  font-weight: normal;
}

.clicksCount {
  text-align: center;
}

.cto,
.coreg,
.cpc {
  padding: 0 12px;

  text-align: center;

  border-radius: 22px;

  font-size: 11px;
}

.switch {
  flex-direction: row !important;
}

.cto {
  color: var(--button-primary-background);
  border: 1px solid var(--button-primary-background);
  background: var(--palette-default-hover);
}

.coreg {
  color: var(--button-primary-active-background);
  border: 1px solid var(--button-primary-active-background);
  background: var(--palette-default-hover);
}

.cpc {
  color: var(--cpc-palette);
  border: 1px solid var(--cpc-palette);
  background: var(--cpc-palette-background);
}

.copyClipboard {
  width: 9px;
  height: 13px;

  cursor: pointer;
}

.cell {
  max-width: 140px;
}

.sourceBtn {
  padding: 8px 0 !important;

  white-space: pre !important;

  color: var(--button-primary-background) !important;

  font-size: 13px;
}

@media (max-width: 992px) {
  .hideOnMobile > div:first-child {
    display: none !important;
  }
}
