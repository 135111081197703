.legend {
  position: sticky;
  bottom: 0;

  display: flex;
  flex-wrap: wrap;

  margin-top: 3px;
  padding: 8px 24px;

  transition: border-radius 125ms ease-in-out;

  border-radius: 4px 4px 16px 16px;
  background-color: var(--gantt-timeline-background);
  gap: 5px 40px;
}

.legend.scrolled {
  border-radius: 4px 4px 0 0;
}

.legendItemWrapper {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.colorBlock {
  min-width: 17px;
  max-width: 17px;
  min-height: 17px;
  max-height: 17px;

  border-radius: 4px;
}

.defaultLegendColor {
  background-color: var(--gantt-timeline-item-default-background);
}
