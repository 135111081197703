.container {
  min-height: 120px;
}

.CTO {
  fill: var(--palette-primary);
}

.COREG {
  fill: var(--palette-info-active);
}

.CPC {
  fill: var(--cpc-palette);
}
