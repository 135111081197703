.container {
  position: relative;
}

.header {
  position: absolute;
  top: 26px;
  right: 26px;

  cursor: pointer;
}

.body {
  padding: 48px;
}

.input {
  margin-bottom: 14px;
}

.body :global(.message-error),
.body :global(.message-default) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.name {
  margin-bottom: 35px;

  font-size: 22px;
  font-weight: 500;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 24px;
}

.types {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.labelType {
  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 10px;
  font-weight: bold;
}

.wrapper {
  margin-bottom: 10px;
}

.imgWrapper {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;

  height: 100%;
  margin-left: 12px;
}

.imgWrapper img {
  width: 12px;
  height: 12px;
}

.inputPadding input {
  padding-left: 28px !important;
}

.errorMsg {
  position: absolute;
  top: 20px;

  width: 80% !important;
  max-width: 80% !important;
}

.time {
  display: flex;
  align-items: center;

  margin-bottom: 13px;
}

.time:last-child {
  margin-bottom: 24px;
}

.icon {
  width: 11px;
  height: 10px;
  margin-right: 6px;
  margin-left: 18px;
}

.date {
  text-transform: capitalize;

  font-size: 13px;
  font-weight: normal;
}
