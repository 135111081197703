.infoPanelWrap {
  position: relative;

  min-height: 250px;
  margin-bottom: 12px;
  padding: 10px 24px 24px;

  color: var(--palette-text-default);
  border-radius: 20px;
  background: var(--palette-default);
}

.deleteHeader {
  position: absolute;
  top: 26px;
  right: 26px;

  cursor: pointer;
}

.deleteHeader img {
  width: 15px;
}

.title {
  margin-bottom: 28px;
}

.title > span {
  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
}

.row {
  display: flex;
  flex-wrap: nowrap;

  margin-bottom: 20px;
}

.rowName {
  display: inline-block;

  width: 87px;
  min-width: 87px;
  margin-right: 12px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.textWrapper {
  margin-top: 5px;
  margin-bottom: 33px;
}

.calendarBody {
  display: flex;
  align-items: center;
}

.calendarBody img {
  margin-right: 7px;
}

.calendarBody img:last-child {
  margin-left: 16px;
}

.boldBody {
  font-weight: 500;
}

.textWrapper .row:last-child {
  margin-bottom: 0;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonsWrapper button:not(:last-child) {
  margin-right: 24px;
}
