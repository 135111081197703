.checkMark {
  width: 36px;
  height: 36px;
}

.title {
  margin-top: 12px;
  margin-bottom: 10px;

  font-size: 22px;
  font-weight: 500;
}

.subtitle {
  font-size: 13px;
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 30px;
}

.buttonsWrapper > button:last-child {
  margin-left: 16px;
}
