.deleteButton {
  color: var(--palette-error);

  font-weight: normal;
}

.editButton,
.copyButton {
  color: var(--palette-text-default);

  font-weight: normal;
}

.clicksCount {
  text-align: center;
}

.icon {
  width: 14px;
  height: 13px;
  margin-right: 6px;
}

.dateWrapper {
  display: flex;
  align-items: center;

  text-transform: capitalize;

  color: var(--palette-text-default);
}

.dotStatus {
  width: 8px;
  height: 8px;
  margin-right: 12px;

  border-radius: 50%;
  background: var(--palette-text-default);
}

.active {
  background: var(--palette-success-transparent);
}

.paused {
  background: var(--palette-warning-transparent);
}

.pending {
  background: var(--palette-secondary-transparent);
}

.completed {
  background: var(--palette-primary-transparent);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.name {
  max-width: 130px;
}

.client {
  max-width: 200px;
}

.cto {
  color: var(--button-primary-background);
  border: 1px solid var(--button-primary-background);
  background: var(--palette-default-hover);
}

.coreg {
  color: var(--button-primary-active-background);
  border: 1px solid var(--button-primary-active-background);
  background: var(--palette-default-hover);
}

.cpc {
  color: var(--cpc-palette);
  border: 1px solid var(--cpc-palette);
  background: var(--cpc-palette-background);
}

.typeStub {
  justify-content: center;
}

.cto,
.coreg,
.cpc {
  margin-right: 3px;
  padding: 0 8px;

  text-align: center;

  border-radius: 22px;

  font-size: 11px;
}

@media (max-width: 520px) {
  .icon {
    display: none;
  }

  .dateCell {
    padding: 12px 0 !important;
  }
}

@media (max-width: 992px) {
  .hideOnMobile > div:first-child {
    display: none !important;
  }
}
