.wrapper {
  position: relative;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.searchHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search {
  margin-right: 10px;
}

.addSmallScreen {
  display: none !important;
}

.searchFormContainer {
  display: flex;
  align-items: center;
}

.tableHeader {
  position: absolute;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
}

.tableHeader button {
  transform: translateY(-250%);
}

@media (max-width: 768px) {
  .addBtn,
  .addSmallScreen {
    display: none !important;
  }

  .search {
    width: 220px !important;
  }

  .table > div:first-child > div:first-child > div:last-of-type {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 905px) {
  .addSmallScreen {
    display: block !important;
  }

  .addBtn {
    display: none !important;
  }
}

@media (min-width: 993px) and (max-width: 1110px) {
  .addSmallScreen {
    display: block !important;
  }

  .addBtn {
    display: none !important;
  }
}
