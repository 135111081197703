.organization {
  display: grid;
  align-items: center;
}

.organizationWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.long {
  min-width: 150px;
  max-width: 150px;
}

.imageWrapper {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;

  width: 36px;
  height: 36px;
  margin: 0 14px 0 0;

  border: 1px solid var(--input-border);
  border-radius: 10px;
}

.organizationField {
  color: var(--label-color);
}

.visitLink {
  padding: 0 !important;

  color: var(--button-text-hover-color) !important;
}
