.collapseWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 6px;
}

button.collapseWrapper {
  height: auto;
  padding: 0;

  font-size: 12px;
}

button.collapseWrapper * {
  color: var(--palette-text-secondary);
}

button:hover.collapseWrapper *,
button:focus.collapseWrapper * {
  color: var(--palette-text-secondary);
}

.leftPart {
  display: flex;
}

.revertArrow {
  transform: rotate(-180deg);
}

.label {
  margin-left: 6px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
}

.inputDate > div {
  flex-wrap: nowrap;
}

.triggerElement {
  width: 100%;
}
