.container {
  display: grid;
  overflow: hidden;
  align-items: center;

  width: 100vw;
  max-width: 100%;
  min-height: 100vh;

  background-color: var(--palette-body);
  grid-template-columns: repeat(2, 1fr);
}

/* Logo */

.logo {
  position: absolute;
  z-index: 15;
  top: 40px;
  left: 40px;

  display: flex;
  align-items: center;
}

.logo .icon {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 12px;

  border-radius: 12px;
}

.logoText {
  color: var(--palette-text-default);

  font-size: 16px;
  font-weight: 500;
}

/*Left side*/

.sidebar {
  position: relative;

  display: flex;
  align-items: center;

  width: 100%;
  height: 100vh;
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding-top: 48px;
  padding-bottom: 59px;
  padding-left: 36px;
}

.images {
  position: absolute;
  top: 50%;
  left: calc(50% - 11px);

  width: calc(100% - 20px);
  min-height: calc(100vh - 60px);

  transform: translate(-50%, -50%);
  object-fit: cover;
}

.leafs {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;
  height: 100vh;

  transform: translate(-50%, -50%);
  object-fit: cover;
}

/* Info */

.info {
  display: flex;
  justify-content: space-around;
}

.option {
  margin-right: 80px;

  color: var(--palette-text-secondary);

  font-size: 12px;
}

/* Right Side Form */

.leafBackground {
  position: absolute;
  top: 50%;
  left: 0;

  max-width: calc(100% - 40px);

  transform: translateY(-50%);
}

.loginForm {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  width: 100%;
  max-width: 500px;
  height: 100%;
}

.title {
  margin-bottom: 15px;

  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
}

.subtitle {
  margin-bottom: 15px;

  color: var(--palette-text-secondary);

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
}

.loginForm form {
  width: calc(100% - 120px);
  max-width: 563px;
}

.loginForm .forgotPassword {
  margin-top: -10px;
  margin-bottom: 24px;
  margin-left: auto;

  cursor: pointer;
  text-align: right;

  color: var(--palette-primary);
}

.errorMessage {
  margin-bottom: 5px;
}

/* Submit */

.btn {
  width: 95px;
  margin: 0 0 0 auto;
}

@media (max-width: 992px) {
  .container {
    display: flex;
  }

  .sidebar {
    display: none;
  }

  .loginForm {
    width: 100%;
  }

  .loginForm form {
    width: calc(100% - 80px);
  }

  .logoText {
    color: var(--palette-text-primary);
  }
}

@media (max-width: 500px) {
}
