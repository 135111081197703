.container {
  padding: 24px 10px;
}

.pageTitle {
  font-size: 22px;
  font-weight: 500;
}

.userWrapper {
  display: flex;
  justify-content: flex-start;

  padding: 24px 0 36px 0;
}

.userInfo {
  display: grid;
  align-items: center;
}

.createdAt {
  margin-top: 5px;
}

.imgWrapper {
  position: relative;
}

.userImg {
  width: 60px;
  height: 60px;

  border-radius: 6px;
}

.info {
  width: 34%;
  margin-left: 24px;
}

.info:last-child {
  width: auto;
}

.status {
  position: absolute;
  right: 0;
  bottom: 5px;

  width: 12px;
  height: 12px;

  border-radius: 50%;
  background: var(--palette-success);
}

.row {
  display: flex;
  justify-content: flex-start;

  width: 100%;
}

.row:last-child {
  margin-bottom: 0;
}

.title,
.titleDate {
  display: flex;
  align-items: center;

  min-width: 40px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.titleDate {
  width: 50%;
}

.label {
  margin-left: 15px;

  color: var(--palette-text-default);

  font-size: 13px;
  font-weight: 400;
}

.date {
  width: 100%;
  max-width: 220px;
  margin-left: 12px;

  white-space: nowrap;
}

.date img {
  margin: 0 6px 0 14px;
}

.date img:first-child {
  margin: 0 6px 0 0;
}

.button {
  margin: 0 auto;
}

.tableHeader {
  margin-bottom: 6px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.tableWrapper {
  margin-bottom: 36px;
  padding: 6px;

  border-radius: 10px;
  background: var(--palette-body);
}

.cell,
.content > div > div:last-of-type:not(:first-of-type) {
  margin-right: auto;
}

@media (max-width: 600px) {
  .info {
    width: 30%;
  }
}
