.searchHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.searchHeaderWrapper button img {
  width: 18px;
  height: 18px;
}

.searchContainer {
  display: flex;
  align-items: center;
}

.searchField {
  margin-right: 10px;
}

.modal {
  width: 496px !important;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .addBtn,
  .addSmallScreen {
    display: none !important;
  }

  .searchField {
    width: 220px !important;
  }

  .table > div:first-child > div:first-child > div:last-of-type {
    display: none;
  }
}

@media (min-width: 769px) and (max-width: 1054px) {
  .addSmallScreen {
    display: block;
  }

  .addBtn {
    display: none !important;
  }
}

@media (min-width: 1055px) {
  .addBtn {
    display: block;
  }

  .addSmallScreen {
    display: none !important;
  }
}
