.container {
  position: relative;
}

.header {
  display: flex;
  justify-content: flex-end;

  width: 100%;
  padding: 25px 25px 0;
}

.header img {
  width: 15px;

  cursor: pointer;
}

.body {
  padding: 10px 48px 48px;
}

.body :global(.message-error),
.body :global(.message-default) {
  margin-top: 4px;
  margin-bottom: 8px;
}

.name {
  max-width: 100%;

  font-size: 22px;
  font-weight: 500;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 24px;
}

.error {
  margin-bottom: 10px;
}

.infoWrapper {
  margin-bottom: 24px;
}

.infoWrapper > div:not(:last-child) {
  margin-bottom: 16px;
}
