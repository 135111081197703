.container {
  display: flex;
  align-content: center;
  justify-content: space-between;

  width: 100%;
  height: 60px;
  padding: 0 48px;

  background-color: var(--palette-default);
}

.selectWrapper {
  display: flex;
  justify-content: left;
}

.wrapper {
  display: flex;
  align-self: center;

  height: 36px;
}

.wrapper:first-of-type {
  margin-right: 55px;
}

.triggerBtn > .userBtn {
  height: 36px;
  margin-top: 14px;
  margin-bottom: 10px;

  background-color: transparent;
}

.triggerBtn > .userBtn:hover {
  background-color: transparent;
}

.tip {
  display: flex;
  align-items: center;

  width: 54px;
  margin-right: 12px;

  text-transform: uppercase;

  color: var(--palette-tertiary-active);

  font-size: 10px;
}

.orgTip {
  width: 83px;
}
