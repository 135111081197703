.link {
  display: flex;
  align-items: center;

  text-decoration: none;

  color: var(--link-primary);
}

.externalIcon {
  margin-right: 7px;
  margin-bottom: 2px;
}
