.container {
  display: flex;
  overflow: auto;
  align-items: center;
  flex-direction: column;

  width: 100vw;
  max-width: 100%;
  min-height: 100vh;

  background-color: var(--palette-default);
}

/* Logo */

.logo {
  position: absolute;
  z-index: 1;
  top: 40px;
  left: 40px;

  display: flex;
  align-items: center;

  width: calc(100% - 100px);
}

.logo img {
  margin-right: 10px;
}

.logoText {
  font-size: 16px;
  font-weight: 500;
}

/* Top Bar */

.topBar {
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: right;

  width: 100%;

  font-size: 14px;
}

.topBar .link {
  color: var(--palette-primary);
}

.leafBackground {
  position: absolute;
  z-index: -1;
  top: -40px;
  left: -40px;

  width: calc(100% + 100px);
  height: 100vh;

  background: url("assets/images/reset-password-leaf.svg") no-repeat center center;
  background-size: cover;
}

/*Left side*/

.sidebar {
  position: relative;

  width: 50%;
  height: 100vh;
}

.content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 48px 36px 59px;

  background: var(--palette-default);
}

/* Info */

.info {
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: space-around;
}

.option {
  text-align: center;

  font-size: 12px;
}

/* Right Side Form */

.loginForm {
  position: relative;
  z-index: 5;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 50%;
  height: 90vh;
}

.title {
  position: relative;

  margin-bottom: 15px;

  color: var(--palette-text-default);

  font-size: 22px;
  font-weight: 500;
  font-style: normal;
}

.subtitle {
  margin-bottom: 20px;

  color: var(--palette-text-secondary);

  font-size: 13px;
  font-weight: normal;
  font-style: normal;
}

.loginForm form {
  width: calc(100% - 120px);
  max-width: 400px;
}

.loginForm .forgotPassword {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 24px;

  cursor: pointer;
  text-align: right;

  color: var(--palette-primary-focus);
}

.resend {
  width: calc(100% - 120px);
  max-width: 400px;
}

.titleImage {
  margin-bottom: -15px;
  margin-left: 15px;
}

.resend .title {
  margin-bottom: 24px;
}

.resend .subtitle {
  margin-bottom: 36px;
}

/* Submit */

.btn {
  width: 95px;
  margin: 0 0 0 auto;
}

@media (max-width: 992px) {
  .sidebar {
    display: none;
  }

  .leafBackground {
    display: none;
  }

  .loginForm {
    width: 100%;
  }

  .loginForm form {
    width: calc(100% - 80px);
  }

  .logoText {
    color: var(--palette-text-default);
  }
}

@media (max-width: 600px) {
  .topBarText {
    display: none;
  }

  .resend {
    width: calc(100% - 95px);
  }

  .titleImage {
    position: absolute;
    top: -17px;
  }
}

@media (max-width: 360px) {
  .titleImage {
    display: none;
  }
}
