.container {
  position: relative;
}

.header {
  position: absolute;
  top: 26px;
  right: 26px;

  cursor: pointer;
}

.header img {
  width: 15px;
}

.body {
  padding: 48px;
}

.body :global(.message-error),
.body :global(.message-default) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.name {
  margin-bottom: 35px;

  font-size: 22px;
  font-weight: 500;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 24px;
}

.errorMsg {
  position: absolute;
  top: 20px;

  width: 80% !important;
  max-width: 80% !important;
}

.img {
  position: absolute;
  top: 10px;
  left: 8px;

  width: 14px;
  height: 14px;
}

.input input {
  padding: 7px 25px !important;
}

.hint {
  margin-bottom: 15px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);
  border-bottom: 1px solid var(--palette-grey-disabled);

  font-family: "Lato", sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 15px;
}
