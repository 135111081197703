.container {
}

.cap {
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 3px;
  padding: 15px 24px 14px;

  transition: border-radius 125ms ease-in-out;

  border-radius: 10px 10px 0 0;
  background-color: var(--gantt-timeline-background);
}

.cap.scrolled {
  border-radius: 0;
}

.header {
  position: sticky;
  position: -webkit-sticky;
  z-index: 1;
  top: 48px;

  display: flex;
  align-items: center;

  height: 40px;
  margin-bottom: 6px;

  border-radius: 0 0 10px 10px;
  background-color: var(--gantt-timeline-background);
}

.titleWrapper {
  display: flex;
  flex-direction: column;

  min-width: 240px;
  max-width: 240px;
  padding: 0 24px;
  row-gap: 5px;
}

.titleWrapper.borderRight {
  justify-content: center;

  height: 100%;

  border-right: 1px solid var(--gantt-timeline-item-title-right-border-color);
}

.timePeriods {
  display: flex;
  overflow: hidden;
  align-items: center;

  width: 100%;
  height: 100%;
  column-gap: 4px;
}

.timePeriodWrapper {
  display: flex;
  justify-content: center;

  width: 100%;
}

.timePeriod {
  position: relative;
}

.timePeriod > span {
  vertical-align: super;

  font-size: 10px;
}

.timePeriod:not(.presentDate) .periodDecoration {
  color: var(--gantt-timeline-period-decoration-color);
}

.timePeriod:not(.presentDate).weekendDay,
.timePeriod:not(.presentDate).weekendDay .periodDecoration {
  color: var(--gantt-timeline-weekend-day-color);
}

.presentDateDecorator {
  position: absolute;
  bottom: -1px;
  left: 50%;

  width: 105%;
  height: 2px;

  transform: translateX(-50%);

  border-radius: 1px;
  background-color: var(--gantt-timeline-present-date-decorator-background);
}

.content {
  position: relative;
  z-index: 0;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  user-select: none;
  row-gap: 6px;
}

.currentTimeLine {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  width: 2px;
  height: 100%;

  pointer-events: none;

  background-color: var(--gantt-timeline-current-time-line-background);
}

.groupWrapper {
}

.groupTitle {
  margin-bottom: -5px;
  padding: 11px 24px 10px;

  text-transform: uppercase;

  color: var(--gantt-timeline-group-title-color);
  border-radius: 10px 10px 0 0;
  background-color: var(--gantt-timeline-background);
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

.itemWrapper {
  position: relative;

  display: flex;
  align-items: center;

  height: 61px;

  background-color: var(--gantt-timeline-background);
}

.itemWrapper:last-child {
  border-radius: 0 0 8px 8px;
}

.itemRangesWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;

  color: var(--gantt-timeline-range-date-color);
}

.itemRangesWrapper * {
  color: var(--gantt-timeline-range-date-color);
}

.itemRangeTime {
  display: flex;
  align-items: center;
  column-gap: 6px;

  font-size: 11px;
}

.itemWrap {
  position: relative;
  z-index: 2;

  display: flex;
  overflow: hidden;
  align-items: center;

  width: 100%;
  height: 100%;
}

.item {
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  height: 80%;

  border-radius: 10px;
  background-color: var(--gantt-timeline-item-default-background);
  gap: 2px;
}

.item.hideContent * {
  display: none !important;
}

.itemTitle {
  position: sticky;
  position: -webkit-sticky;
  left: 0;

  padding: 0 24px;

  color: var(--gantt-timeline-item-color);
}

.itemDescription {
  position: sticky;
  position: -webkit-sticky;
  left: 0;

  padding: 0 24px;

  color: var(--gantt-timeline-item-color);
}

.grid {
  position: absolute;
  z-index: 0;
  left: 240px;

  display: flex;
  align-items: center;

  width: calc(100% - 240px);
  height: inherit;
  grid-column-gap: 4px;
  column-gap: 4px;
}

.gridItem {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;

  border-right: 1px dashed var(--gantt-timeline-item-title-right-border-color);
}

.gridItem:last-of-type {
  border-right: none;
}
