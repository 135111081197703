.wrapper {
  display: flex;
  align-items: center;
}

.title {
  margin-right: 18px;

  text-transform: uppercase;

  color: var(--palette-text-secondary);

  font-size: 12px;
  font-weight: 600;
}

.body {
  display: flex;
  align-items: center;

  color: var(--palette-text-secondary);
}

.body span:not(:last-child) {
  margin-right: 12px;
}

.body img {
  margin-right: 7px;
}
