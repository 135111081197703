.container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  position: absolute;
  top: 26px;
  right: 26px;

  cursor: pointer;
}

.body {
  width: 100%;
  padding: 25px 48px;
}

.input {
  margin-bottom: 14px;
}

.body :global(.message-error),
.body :global(.message-default) {
  margin-top: 4px;
  margin-bottom: 4px;
}

.name {
  margin-top: 30px;
  margin-bottom: 5px;

  font-size: 22px;
  font-weight: 500;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 24px;
}
